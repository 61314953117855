import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { Globals } from '@app/_helpers/globals';
import { domain } from 'process';
import { Utils } from '@app/_helpers/utils';

@Injectable()
export class AuthService {
  assetsSetting = {
    logo: 'images/logo.png',
    favicon: 'favicon.ico'
  };

  constructor(private http: HttpClient, private utils :Utils, private globals: Globals, private router: Router) {
    try {
      let temp = JSON.parse(localStorage.getItem('cud'));
      if(temp) {
        this.globals.id = temp.id;
        this.globals.clientid = temp.clientid;
        this.globals.emailid = temp.emailid;
        this.globals.fullname = temp.fullname;
        this.globals.countryCode = temp.countryCode;
        this.globals.seatId = temp.seatId;
        this.globals.token = temp.token;
        this.globals.seatId = temp.seatId;
        this.globals.role = temp.role;
        this.globals.privilegeId = temp.privilegeId;
        this.globals.teamLead = temp.teamLead
        this.globals.newSignup = temp.newSignup
        this.globals.loader = temp.loader
        this.globals.isShowMediaPlanning = temp.isShowMediaPlanning;
      } else {
        console.log('token is empty');
      }
    } catch(error) {
      console.log('error during token recovery');
    }
    // this.setFavicon(this.assetsSetting.favicon);
  }

  setFavicon(favicon) {
    console.log("setFavicon",favicon)
    var linkElement = document.createElement("link");
    linkElement.setAttribute("id", "FaviconIcon");
    linkElement.setAttribute("rel", "icon");
    linkElement.setAttribute("type", "image/png");
    linkElement.setAttribute("href", favicon);
    document.head.appendChild(linkElement);
  }

  loginRequest(params) {
    return this.http.post(`${environment.endpoint}/ip/auth?${params}`, {}).pipe(map((response: any) => {
      console.log(response);
      if(response) {
        this.globals.clientid = response.clientid;
        this.globals.emailid = response.emailid;
        this.globals.fullname = response.fullname;
        this.globals.seatId = response.seatId;
        this.globals.countryCode = response.countryCode
        this.globals.token = response.tokenId;
        this.globals.role = response.groupid;
        this.globals.privilegeId = response.privilegeId;
        this.globals.teamLead = response.teamLead;
        this.globals.newSignup = response.newSignup;
        this.globals.loader = response.loader
        this.globals.isShowMediaPlanning = response.isShowMediaPlanning;
        this.globals.isJavaScriptCreative = response.isJavaScriptCreative;

        console.log(this.globals);
        let temp = JSON.stringify(this.globals);
        localStorage.setItem('cud', temp);
        return { status: true, data: response };
      } else {
        return { status: false, message: response.message };
      }
    }));
  }


  activationAccountRequest(params) {
    return this.http.get(`${environment.endpoint}/ip/activationdata?userId=${params}`).pipe(map((response: any) => {
      console.log(response);
      if (response.data) {
        this.globals.clientid = response.data.clientid;
        this.globals.emailid = response.data.emailid;
        this.globals.fullname = response.data.fullname;
        this.globals.seatId = response.data.seatId;
        this.globals.token = response.data.tokenId;
        this.globals.role = response.data.groupid;
        this.globals.privilegeId = response.data.privilegeId;
        this.globals.newSignup = response.data.newSignup;
        console.log(this.globals);
        let temp = JSON.stringify(this.globals);
        localStorage.setItem('cud', temp);
        return { status: true, data: response.data };
      } else {
        return { status: false, message: response.message };
      }
    }));
  }


  getSeatDetailsLoginRequest() {
    return this.http.get(`${environment.endpoint}/ip/seatdetail?sdn=${environment.domain}`)
  }

  logout(data) {
    this.utils.eraseCookie("Visit")
    this.utils.eraseCookie("groupid")
    localStorage.removeItem('cud');
    this.globals.token = null;
    this.globals.role = null;
    this.globals.teamLead = false;
    this.globals.loader = null;
    this.globals.newSignup = false;
    this.router.navigate(['/auth/login']);
  }

  forgotPassword(data) {
    return this.http.post(`${environment.endpoint}/ip/forgetpasswordrequest`, data);
  }

  changePassword(params: any) {
    return this.http.post(`${environment.endpoint}/ip/changepassword`, params);
  }

  signUpRequest(domain,loginFormValues) {
    return this.http.post(`${environment.endpoint}/ip/signupsubadvt?sdn=${domain}`, loginFormValues);
  }

  changeExpirePassword(params) {
    return this.http.post(`${environment.endpoint}/ip/ChangeExpirePassword`, params);
  }
  signUpAgencyAdvertiserRequest(domain,loginFormValues) {
    return this.http.post(`${environment.endpoint}/ip/signupwithsites`, loginFormValues);
  }
}
